// Vehicle gallery
const vehiclePresntation = document.querySelectorAll('.vehicle-presentation')
const vehicleViewer = document.querySelectorAll('.vehicle-viewer')

const showVehicle = id => {
	vehiclePresntation.forEach(section => (section.style.display = 'none'))
	document.getElementById(id).style.display = 'flex'
}
const showSpecs = id => {
	vehicleViewer.forEach(section => (section.style.display = 'none'))
	document.getElementById(id).style.display = 'flex'
}

// Syling navigation regarding the page user is on
const path = window.location.pathname
const page = path.split('/').pop()
const domain = window.location.origin
const navItems = document.querySelectorAll('.navbar__link-item')

const styleNav = () => {
	if (domain.includes('resq-service.com')) {
		navItems.forEach(item => {
			if (
				item.getAttribute('href').replace('.html', '') ===
				page.replace('.html', '')
			) {
				item.classList.remove('navbar__link-item--hot')
				item.classList.add('active')
				item.ariaCurrent = 'page'
			}
		})
	} else {
		navItems.forEach(item => {
			if (item.getAttribute('href') === page) {
				item.classList.remove('navbar__link-item--hot')
				item.classList.add('active')
				item.ariaCurrent = 'page'
			}
		})
	}
}
styleNav()

// Mobile navigation
const navToggler = document.querySelector('.navbar__toggler')
const navBar = document.querySelector('.navbar__links')
const navBarContainer = document.querySelector('.navbar')

const toggleMobileNavigation = () => navBar.classList.toggle('visible')

const closeNavbar = e => {
	if (
		navBar.classList.contains('visible') &&
		e.target !== navToggler &&
		!navToggler.contains(e.target) &&
		e.target !== navBar &&
		!navBarContainer.contains(e.target)
	) {
		toggleMobileNavigation()
		document.body.removeEventListener('click', closeNavbar)
	} else if (!navBar.classList.contains('visible')) {
		document.body.removeEventListener('click', closeNavbar)
	}
}

navToggler.addEventListener('click', () => {
	toggleMobileNavigation()
	document.body.addEventListener('click', closeNavbar)
})

// Tables highlighter
const firstRows = document.querySelectorAll('[data-row="first"]')
const secondRows = document.querySelectorAll('[data-row="second"]')
const thirdRows = document.querySelectorAll('[data-row="third"]')
const fourthRows = document.querySelectorAll('[data-row="fourth"]')
const fifthRows = document.querySelectorAll('[data-row="fifth"]')
const sixthRows = document.querySelectorAll('[data-row="sixth"]')

const highlightRow = function (row) {
	row.forEach(cell => cell.classList.add('options-table__row--highlighted'))
}
const resetRow = function (row) {
	row.forEach(cell => cell.classList.remove('options-table__row--highlighted'))
}
firstRows.forEach(cell =>
	cell.addEventListener('mouseover', () => {
		highlightRow(firstRows)
	})
)
firstRows.forEach(cell =>
	cell.addEventListener('mouseout', () => {
		resetRow(firstRows)
	})
)
secondRows.forEach(cell =>
	cell.addEventListener('mouseover', () => {
		highlightRow(secondRows)
	})
)
secondRows.forEach(cell =>
	cell.addEventListener('mouseout', () => {
		resetRow(secondRows)
	})
)
thirdRows.forEach(cell =>
	cell.addEventListener('mouseover', () => {
		highlightRow(thirdRows)
	})
)
thirdRows.forEach(cell =>
	cell.addEventListener('mouseout', () => {
		resetRow(thirdRows)
	})
)
fourthRows.forEach(cell =>
	cell.addEventListener('mouseover', () => {
		highlightRow(fourthRows)
	})
)
fourthRows.forEach(cell =>
	cell.addEventListener('mouseout', () => {
		resetRow(fourthRows)
	})
)
fifthRows.forEach(cell =>
	cell.addEventListener('mouseover', () => {
		highlightRow(fifthRows)
	})
)
fifthRows.forEach(cell =>
	cell.addEventListener('mouseout', () => {
		resetRow(fifthRows)
	})
)
sixthRows.forEach(cell =>
	cell.addEventListener('mouseover', () => {
		highlightRow(sixthRows)
	})
)
sixthRows.forEach(cell =>
	cell.addEventListener('mouseout', () => {
		resetRow(sixthRows)
	})
)

// Slider
const carouselInner = document.querySelector('.carousel__inner')
const carouselItems = document.querySelectorAll('.carousel__item')
const carouselSpeed = 5000

let index = 0

const handleCarousel = () => {
	index++
	changeImage()
}

if (carouselInner) {
	let startCarousel = setInterval(handleCarousel, carouselSpeed)
}
const changeImage = () => {
	// index = (index + 1) % carouselItems.length;
	if (index > carouselItems.length - 1) {
		index = 0
	} else if (index < 0) {
		index = carouselItems.length - 1
	}
	carouselItems.forEach(item => item.classList.remove('active'))
	carouselItems[index].classList.add('active')
}

//COOKIES
const cookie = document.querySelector('.cookie')
const cookieBtn = document.querySelector('.accept-cookie')

const showCookie = () => {
	const cookieEaten = localStorage.getItem('cookie')
	if (cookieEaten) {
		cookie.classList.add('cookie__hide')
	}
}

const handleCookies = () => {
	localStorage.setItem('cookie', 'true')
	cookie.classList.add('cookie__hide')
}

cookieBtn.addEventListener('click', handleCookies)

function isLocalStorageAvailable() {
	const test = 'test'
	try {
		localStorage.setItem(test, test)
		localStorage.removeItem(test)
		return true
	} catch (e) {
		return false
	}
}

isLocalStorageAvailable()

if (!isLocalStorageAvailable) {
	cookie.classList.add('cookie__hide')
}

showCookie()